import { createContext } from 'react';
import FeatureFlags, { FeatureFlagEnum } from '../types/FeatureFlags';

export type AppContextType = {
  featureFlags: FeatureFlags | undefined;
  isFeatureFlagSet: (flag: FeatureFlagEnum) => boolean;
};
// add information once its on the backend api url
// storeID: number;
// tenneteID: number;

const AppContext = createContext<AppContextType | undefined>(undefined);

export default AppContext;
