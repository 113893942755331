import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const DownCarrotIcon = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="DownCarrot"
    isActive={isActive}
    viewBox="0 -150 910 910"
    stroke-width="20"
    {...other}
  >
    <path d="M505.752,123.582c-8.331-8.331-21.839-8.331-30.17,0L256,343.163L36.418,123.582c-8.331-8.331-21.839-8.331-30.17,0 s-8.331,21.839,0,30.17l234.667,234.667c8.331,8.331,21.839,8.331,30.17,0l234.667-234.667 C514.083,145.42,514.083,131.913,505.752,123.582z" />
  </SvgIcon>
);

export default DownCarrotIcon;
